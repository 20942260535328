var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { display: _vm.display },
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("h2", { staticClass: "text-xl font-bold text-brandPurple" }, [
            _vm._v("Choose payment plan"),
          ]),
          _c("p", [
            _vm._v(
              "Automate the process, you always change this on the card page"
            ),
          ]),
          _vm._l(
            _vm.allowedPaymentPlans.defaultPaymentPlans,
            function (payment) {
              return _c(
                "div",
                {
                  staticClass: "mt-6 mb-7 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("style", payment)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "payment-options" }, [
                    _c("div", [
                      _vm.type.id === payment.id
                        ? _c("img", {
                            staticClass: "dot-active mr-4",
                            attrs: {
                              src: require("@/assets/icons/selected.svg"),
                              alt: "",
                            },
                          })
                        : _c("div", { staticClass: "dot mr-4" }),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        { staticClass: "text-lg font-semibold text-grey" },
                        [_vm._v(_vm._s(payment.name))]
                      ),
                      _c("p", { staticClass: "text-sm text-grey" }, [
                        _vm._v(_vm._s(payment.description)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }
          ),
          _vm.flexiblePayment
            ? _c(
                "div",
                {
                  staticClass: "mt-6 mb-7 cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("style", _vm.flexiblePayment)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "payment-options" }, [
                    _c("div", [
                      _vm.type.name && !_vm.checkIfDefaultPlan(_vm.type.id)
                        ? _c("img", {
                            staticClass: "dot-active mr-4",
                            attrs: {
                              src: require("@/assets/icons/selected.svg"),
                              alt: "",
                            },
                          })
                        : _c("div", { staticClass: "dot mr-4" }),
                    ]),
                    _c("div", [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-lg font-semibold text-grey flex items-center",
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.flexiblePayment.name) + " "),
                          _c(
                            "span",
                            {
                              staticClass:
                                "px-3 py-1 text-white bg-brandPink rounded-lg text-xs ml-2",
                            },
                            [_vm._v("New")]
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "text-sm text-grey" }, [
                        _vm._v(_vm._s(_vm.flexiblePayment.description)),
                      ]),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _c("transition", { attrs: { name: "slideX", mode: "out-in" } }, [
            _c(
              "div",
              {
                staticClass:
                  "spam-notice bg-lightBlue border border-lightBlue-2 mb-4 mt-6",
                class: [
                  _vm.checkIfDefaultPlan(_vm.type.id) &&
                  _vm.type.customerFeePercent > 0
                    ? ""
                    : "invisible",
                ],
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "20",
                      height: "20",
                      viewBox: "0 0 20 20",
                      fill: "none",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                        fill: "#2490BE",
                      },
                    }),
                  ]
                ),
                _c("p", { staticClass: "text-sm" }, [
                  _vm._v(" You’ll be charged "),
                  _c("span", [
                    _vm._v(_vm._s(_vm.type.customerFeePercent) + "%"),
                  ]),
                  _vm._v(" for every transaction you make with this plan. "),
                ]),
              ]
            ),
          ]),
          _c("Button", {
            staticClass: "mt-2 mb-6",
            attrs: {
              disabled: !_vm.type.name,
              loading: _vm.loading,
              text: _vm.checkIfDefaultPlan(_vm.type.id)
                ? "Select"
                : "Configure",
              width: "w-full",
            },
            on: {
              click: function ($event) {
                return _vm.selectPlan()
              },
            },
          }),
        ],
        2
      ),
      _c(
        "Modal",
        {
          attrs: {
            display: _vm.displayFlexibleOptions,
            title: "Flexible payment configuration",
          },
          on: {
            close: function ($event) {
              _vm.displayFlexibleOptions = false
            },
          },
        },
        [
          _c("div", { staticClass: "pb-4 bg-white rounded-lg" }, [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-between items-center text-xs text-dark",
              },
              [
                _c("p", { staticClass: "text-sm text-grey" }, [
                  _vm._v(
                    "How many percentage are you paying upfront? (min of " +
                      _vm._s(_vm.range.min) +
                      "%)"
                  ),
                ]),
                _c("p", { staticClass: "calculator-input ml-5 " }, [
                  _vm._v(_vm._s(_vm.config.upfrontAmountPercentage) + "%"),
                ]),
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.config.upfrontAmountPercentage,
                  expression: "config.upfrontAmountPercentage",
                },
              ],
              staticClass: "styled-slider mt-2 w-full",
              attrs: {
                type: "range",
                name: "",
                id: "",
                f: "rangeInput",
                min: _vm.range.min,
                max: _vm.range.max,
                step: _vm.range.step,
              },
              domProps: { value: _vm.config.upfrontAmountPercentage },
              on: {
                __r: function ($event) {
                  return _vm.$set(
                    _vm.config,
                    "upfrontAmountPercentage",
                    $event.target.value
                  )
                },
              },
            }),
            _c(
              "div",
              { staticClass: "range-amount flex justify-between items-center" },
              [
                _c("p", { staticClass: "text-xs text-lightGrey-2" }, [
                  _vm._v(_vm._s(_vm.range.min) + "%"),
                ]),
                _c("p", { staticClass: "text-xs text-lightGrey-2" }, [
                  _vm._v(_vm._s(_vm.range.max) + "%"),
                ]),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("p", { staticClass: "text-sm text-grey" }, [
                _vm._v("Select payment duration"),
              ]),
              _c("Select", {
                staticClass: "mb-12 mt-2",
                attrs: {
                  options: _vm.durationOptions,
                  reduce: function (value) {
                    return value.value
                  },
                },
                model: {
                  value: _vm.config.numberOfRepayment,
                  callback: function ($$v) {
                    _vm.$set(_vm.config, "numberOfRepayment", $$v)
                  },
                  expression: "config.numberOfRepayment",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "spam-notice bg-lightBlue border border-lightBlue-2 mb-4 mt-10",
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "20",
                    height: "20",
                    viewBox: "0 0 20 20",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z",
                      fill: "#2490BE",
                    },
                  }),
                ]
              ),
              _c("p", { staticClass: "text-sm" }, [
                _vm._v(" You’ll be charged "),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.getSingleFee(
                        _vm.config.upfrontAmountPercentage,
                        _vm.config.numberOfRepayment
                      )
                    ) + "%"
                  ),
                ]),
                _vm._v(" for every transaction you make with this plan. "),
              ]),
            ]
          ),
          _c("Button", {
            staticClass: "mt-2",
            attrs: { text: "Select", width: "w-full" },
            on: { click: _vm.setPaymentConfig },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }