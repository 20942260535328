<template>
  <div>
    <Modal :display="display" @close="$emit('close')">
      <h2 class="text-xl font-bold text-brandPurple">Choose payment plan</h2>
      <p>Automate the process, you always change this on the card page</p>
      <div class="mt-6 mb-7 cursor-pointer" v-for="payment in allowedPaymentPlans.defaultPaymentPlans"
        @click="$emit('style', payment)">
        <div class="payment-options">
          <div>
            <img src="@/assets/icons/selected.svg" alt="" class="dot-active mr-4" v-if="type.id === payment.id" />
            <div class="dot mr-4" v-else></div>
          </div>
          <div>
            <p class="text-lg font-semibold text-grey">{{ payment.name }}</p>
            <p class="text-sm text-grey">{{ payment.description }}</p>
          </div>
        </div>
      </div>
      <!-- <Hr /> -->
      <div v-if="flexiblePayment" class="mt-6 mb-7 cursor-pointer" @click="$emit('style', flexiblePayment)">
        <div class="payment-options">
          <div>
            <img src="@/assets/icons/selected.svg" alt="" class="dot-active mr-4"
              v-if="type.name && !checkIfDefaultPlan(type.id)" />
            <div class="dot mr-4" v-else></div>
          </div>
          <div>
            <p class="text-lg font-semibold text-grey flex items-center">
              {{ flexiblePayment.name }}
              <span class="px-3 py-1 text-white bg-brandPink rounded-lg text-xs ml-2">New</span>
            </p>
            <p class="text-sm text-grey">{{ flexiblePayment.description }}</p>
          </div>
        </div>
      </div>

      <transition name="slideX" mode="out-in">
        <div :class="[checkIfDefaultPlan(type.id) && type.customerFeePercent > 0 ? '' : 'invisible']"
          class="spam-notice bg-lightBlue border border-lightBlue-2 mb-4 mt-6">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
              fill="#2490BE" />
          </svg>
          <p class="text-sm">
            You’ll be charged
            <span>{{ type.customerFeePercent }}%</span> for
            every transaction you make with this plan.
          </p>
        </div>
      </transition>

      <Button :disabled="!type.name" :loading="loading" :text="checkIfDefaultPlan(type.id) ? 'Select' : 'Configure'"
        width="w-full" class="mt-2 mb-6" @click="selectPlan()" />
    </Modal>

    <Modal :display="displayFlexibleOptions" @close="displayFlexibleOptions = false"
      title="Flexible payment configuration">
      <div class="pb-4 bg-white rounded-lg">
        <div class="flex justify-between items-center text-xs text-dark">
          <p class="text-sm text-grey">How many percentage are you paying upfront? (min of {{ range.min }}%)</p>
          <p class="calculator-input ml-5 ">{{ config.upfrontAmountPercentage }}%</p>
        </div>
        <input class="styled-slider mt-2 w-full" v-model="config.upfrontAmountPercentage" type="range" name="" id=""
          f="rangeInput" :min="range.min" :max="range.max" :step="range.step" />
        <div class="range-amount flex justify-between items-center">
          <p class="text-xs text-lightGrey-2">{{ range.min }}%</p>
          <p class="text-xs text-lightGrey-2">{{ range.max }}%</p>
        </div>
      </div>

      <div class="mt-5">
        <p class="text-sm text-grey">Select payment duration</p>
        <Select class="mb-12 mt-2" :options="durationOptions" v-model="config.numberOfRepayment"
          :reduce="(value) => value.value" />
      </div>

      <div class="spam-notice bg-lightBlue border border-lightBlue-2 mb-4 mt-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M9 5H11V7H9V5ZM9 9H11V15H9V9ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
            fill="#2490BE" />
        </svg>
        <p class="text-sm">
          You’ll be charged <span>{{ getSingleFee(config.upfrontAmountPercentage, config.numberOfRepayment) }}%</span> for
          every transaction you make with this plan.
        </p>
      </div>
      <Button text="Select" width="w-full" class="mt-2" @click="setPaymentConfig" />
    </Modal>
  </div>
</template>
<script>
import Hr from "@/UI/Hr";
import { Modal } from "@/UI/Modals";
import { Button } from "@/UI/Button";
import { Select } from "@/UI/Input";

// getFees, flexiblePayment, allCreditCardFees, getSingleFee, config imported from zillaCard mixin
import zillaCard from "@/mixins/zillaCard";
import { DatePicker } from "v-calendar";


export default {
  mixins: [zillaCard],
  components: {
    Modal,
    Hr,
    Button,
    Select,
    DatePicker
  },
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Object,
      default: () => { },
    },
    schedules: {
      type: Array,
      default: () => [],
      required: false,
    },
    flexiLoading: {
      type: Boolean,
      default: false,
    }
  },

  mounted() {
    this.getFees({ isCreditCard: true });
  },

  data() {
    return {
      displayFlexibleOptions: false,
      range: {
        min: 75,
        max: 100,
        step: 5
      },
      config: {
        numberOfRepayment: 1,
        upfrontAmountPercentage: 25
      },
    }
  },

  computed: {
    durationOptions() {
      let options = [];
      for (let i = this.flexiblePayment.repaymentDuration.minimumValue; i <= this.flexiblePayment.repaymentDuration.maximumValue; i++) {
        let fee = this.allCreditCardFees[this.config?.upfrontAmountPercentage][i - 1].fees[0].percentage;
        let data = {
          label: `${i} month${i > 1 ? "s" : ""}   <span class="text-sm ml-3 text-lightGrey-2">(${fee}% fee)</span>`,
          value: i,
          interest: this.allCreditCardFees[this.config?.upfrontAmountPercentage][i - 1],
        };
        options.push(data);
      }
      return options;
    },
  },

  watch: {
    schedules(value) {
      if (value.length > 0 && this.type === 'flexible') {
        this.config.numberOfRepayment = this.schedules.length - 1;
        value.forEach((item) => {
          if (item.immediate) {
            this.config.upfrontAmountPercentage = item.amountPercentage;
          }
        });
      }
    },
    flexiblePayment: {
      immediate: true,
      handler(val) {
        if (val) {
          this.range.min = this.flexiblePayment?.minimumUpfrontAmountPercentage;
          this.range.step = this.flexiblePayment?.minimumUpfrontAmountPercentageStep;
        }
      }
    },
  },

  methods: {
    selectFlexiblePayments() {
      this.displayFlexibleOptions = true;
    },
    setPaymentConfig() {
      this.$emit('flexiUpdated', this.config);
      this.$emit('selected');
      this.displayFlexibleOptions = false;
    },
    selectPlan() {
      if (this.checkIfDefaultPlan(this.type.id)) {
        this.$emit('selected');
      } else {
        this.selectFlexiblePayments();
      }

    }
  }

};
</script>
<style scoped>
.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #d5ccff;
}

.dot-active {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid #d5ccff;
  padding: 2px;
}

.style-container {
  display: grid;
  grid-template-columns: 85% 1fr;
  grid-gap: 10px;
  align-content: center;
}

.guest-notice {
  display: grid;
  grid-template-columns: 5% 1fr;
  grid-gap: 15px;
  place-items: center;
  background: rgba(243, 243, 246, 0.5);
  border-radius: 4px;
  padding: 12px;
}

.date-input {
  border: 1px solid #EDEDF0;
  border-radius: 5px;
}

.month-select {
  border: 1px solid #EDEDF0;
  border-radius: 5px;
}

.formLabel {
  margin-bottom: 0px !important;
}

.date-info {
  border: 1px solid #CCECFF !important;
  background: #F5FBFF;
  color: #06263C;
  display: grid;
  grid-template-columns: 5% 1fr;
  grid-gap: 15px;
  border-radius: 4px;
  padding: 12px;
  place-items: center;
  position: relative;
}

.vc-pane {
  background: #f6f6f6 !important;
  max-width: 100%;
}

.calculator-input {
  padding: 8px 18px;
  border: 1px solid #6956C8;
  border-radius: 8px;
  background: white;
  font-size: 16px;
  outline: none;
  -webkit-appearance: none;
  transition: all 0.3s;
  min-width: 4.9rem;
}

input[type="range"].styled-slider {
  height: 2.2em;
  -webkit-appearance: none;
}

.flexible-payment-options {
  height: 56px;
  border-radius: 8px;
  background: #ffffff;
  /* line / default */

  border: 1px solid #ededf0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



/*progress support*/
input[type="range"].styled-slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 20px + var(--ratio) * (100% - 20px));
}

input[type="range"].styled-slider:focus {
  outline: none;
}

/*webkit*/
input[type="range"].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 1em;
  background: #6956c8;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: calc(4px * 0.5 - 20px * 0.5);
}

input[type="range"].styled-slider::-webkit-slider-runnable-track {
  height: 4px;
  border: none;
  border-radius: 0.5em;
  background: #ebeaee;
  box-shadow: none;
}

input[type="range"].styled-slider::-webkit-slider-thumb:hover {
  background: #6956c8;
}

input[type="range"].styled-slider:hover::-webkit-slider-runnable-track {
  background: #ebeaee;
}

input[type="range"].styled-slider::-webkit-slider-thumb:active {
  background: #6956c8;
}

input[type="range"].styled-slider:active::-webkit-slider-runnable-track {
  background: #ebeaee;
}

input[type="range"].styled-slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#d5ccff, #d5ccff) 0 / var(--sx) 100% no-repeat, #ebeaee;
}

input[type="range"].styled-slider.slider-progress:hover::-webkit-slider-runnable-track {
  background: linear-gradient(#d5ccff, #d5ccff) 0 / var(--sx) 100% no-repeat, #ebeaee;
}

input[type="range"].styled-slider.slider-progress:active::-webkit-slider-runnable-track {
  background: linear-gradient(#d5ccff, #d5ccff) 0 / var(--sx) 100% no-repeat, #ebeaee;
}

/*mozilla*/
input[type="range"].styled-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 1em;
  background: #6956c8;
  border: none;
  box-shadow: 0 0 2px black;
}

input[type="range"].styled-slider::-moz-range-track {
  height: 4px;
  border: none;
  border-radius: 0.5em;
  background: #ebeaee;
  box-shadow: none;
}

input[type="range"].styled-slider::-moz-range-thumb:hover {
  background: #6956c8;
}

input[type="range"].styled-slider:hover::-moz-range-track {
  background: #ebeaee;
}

input[type="range"].styled-slider::-moz-range-thumb:active {
  background: #6956c8;
}

input[type="range"].styled-slider:active::-moz-range-track {
  background: #ebeaee;
}

input[type="range"].styled-slider.slider-progress::-moz-range-track {
  background: linear-gradient(#d5ccff, #d5ccff) 0 / var(--sx) 100% no-repeat, #ebeaee;
}

input[type="range"].styled-slider.slider-progress:hover::-moz-range-track {
  background: linear-gradient(#d5ccff, #d5ccff) 0 / var(--sx) 100% no-repeat, #ebeaee;
}

input[type="range"].styled-slider.slider-progress:active::-moz-range-track {
  background: linear-gradient(#d5ccff, #d5ccff) 0 / var(--sx) 100% no-repeat, #ebeaee;
}

/*ms*/
input[type="range"].styled-slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type="range"].styled-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  border-radius: 1em;
  background: #6956c8;
  border: none;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-track {
  height: 4px;
  border-radius: 0.5em;
  background: #ebeaee;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type="range"].styled-slider::-ms-thumb:hover {
  background: #6956c8;
}

input[type="range"].styled-slider:hover::-ms-track {
  background: #ebeaee;
}

input[type="range"].styled-slider::-ms-thumb:active {
  background: #6956c8;
}

input[type="range"].styled-slider:active::-ms-track {
  background: #ebeaee;
}

input[type="range"].styled-slider.slider-progress::-ms-fill-lower {
  height: 4px;
  border-radius: 0.5em 0 0 0.5em;
  margin: -undefined 0 -undefined -undefined;
  background: #d5ccff;
  border: none;
  border-right-width: 0;
}

input[type="range"].styled-slider.slider-progress:hover::-ms-fill-lower {
  background: #d5ccff;
}

input[type="range"].styled-slider.slider-progress:active::-ms-fill-lower {
  background: #d5ccff;
}

.payment-options {
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-gap: 5px;
  align-items: center;
}
</style>
<style>
.flexible-payment-date-picker {
  text-align: center;
  width: 100%;
  border: none;
  background: #f6f6f6;
}
</style>
