import { mapGetters, mapActions, mapState } from 'vuex'

export default {
    computed: {
        ...mapState({
            allCreditCardFees: (state) => state?.userInfo?.allCreditCardFees,
        }),
        ...mapGetters("card", ["allowedPaymentPlans"]),
        flexiblePayment() {
            return this.allowedPaymentPlans.flexiblePaymentPlanConfig;
        },
        duration() {
            return this.allowedPaymentPlans?.flexiblePaymentPlanConfig?.repaymentDuration;
        },
    },

    methods: {
        ...mapActions("card", [
            "getAllowedPaymentPlans",
        ]),
        ...mapActions("userInfo", ["getAllCustomerFees"]),

        getFees(payload) {
            this.getAllCustomerFees(payload)
                .then((res) => {
                })
        },

        getSingleFee(percent, num) {
            let fee = this.allCreditCardFees[percent][(num - 1)].fees[0].percentage;
            return fee;
        },

        capitalize(word) {
            let string = word.toLowerCase();
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        getPaymentPlans() {
            this.getAllowedPaymentPlans();
        },

        checkIfDefaultPlan(id) {
            let item = this.allowedPaymentPlans.defaultPaymentPlans.find((plan) => plan.id === id);
            if (item) {
                return true;
            } else {
                return false;
            }
        }
    }
}